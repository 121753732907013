export const CURRENCY_SIGNS = {
    EUR: '€',
    USD: '$',
}

export const getCurrencySign = (iso3) => {
    if (!iso3) {
        throw new Error('iso3 required')
    }
    return CURRENCY_SIGNS[iso3.toUpperCase()]
}

export enum PaymentPeriod {
    monthly = 'monthly', 
    annually = 'annually',
}


/**
 * Cancellation
 */

export interface CancelFeedback {
    reasons?: CancelReason[]
    feedback?: string
}

export enum CancelReason {
    priceTooExpensive = 'priceTooExpensive',
    featuresRequired = 'featuresRequired',
    alternativeFound = 'alternativeFound',
    customerServiceIssues = 'customerServiceIssues',
    qualityIssues = 'qualityIssues',
    noLongerNeeded = 'noLongerNeeded',
    otherReasons = 'otherReasons',
}