import { YoioApp } from "modules/yoio/YoioTypes"
import { getCurrentPlanComparison } from "modules/yoio/api/appApi"
import { SubscriptionPlanComparisonDto } from "modules/yoio/payment/model/PaymentTypes"
import { useApp } from "modules/yoio/useApp"
import useSWR from "swr"
import { paramsToQueryString } from "utils/urlUtils"
import { handleResponseDataGetContent } from "utils/useResolvedV2"

export const useAppSubscriptionPlanComparisonSwr = (params: { includeHiddenPlans?:boolean, includePaidPlansOnly?:boolean, includeCustomPlans?:boolean, subscribableForWorkspace?:string, showUnlimitedAsIncluded?:boolean }, immutable?: boolean, load?: boolean) => {

    const { includeHiddenPlans, includePaidPlansOnly, includeCustomPlans, subscribableForWorkspace, showUnlimitedAsIncluded } = params

    const queryString = paramsToQueryString( { includeHiddenPlans, includePaidPlansOnly, includeCustomPlans, subscribableForWorkspace, showUnlimitedAsIncluded })

    const { app } = useApp() as { app: YoioApp }

    let swr = useSWR<SubscriptionPlanComparisonDto>(load && app?.key ? `/apps/${app.key}/planComparison${queryString}` : null, ()=>getCurrentPlanComparison(includeHiddenPlans, includePaidPlansOnly, includeCustomPlans, subscribableForWorkspace, showUnlimitedAsIncluded).then(handleResponseDataGetContent),immutable?{
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    }:null)

    return swr
}