import { notNullNotUndefined } from "utils/objectUtils";
import { LandingPageElement } from "../LandingPageTypes";




export const findContent = (element: string, content: LandingPageElement[]): LandingPageElement[] => {
    notNullNotUndefined(element)
    notNullNotUndefined(content)

    const results: LandingPageElement[] = []

    for (let i = 0; i < content.length; i++) {
        const el = content[i]

        // Search child content
        if (el.props?.content && Array.isArray(el.props.content)) {
            const childResults = findContent(element, el.props.content)
            if (childResults?.length > 0) {
                results.push(...childResults)
            }
        } 

        if (el.type === element) {
            results.push(el)
        }
    }

    return results;
}

/**
 * Examples:
 * ['locale:de'], 'development', 'de' -> true
 * ['locale:de'], 'production', 'de' -> true
 * ['locale:de'], 'production', 'en' -> false
 * ['development','locale:de'], 'production', 'de' -> false
 * 
 * @param visibleIf 
 *      
 * @param environment 
 * @param locale 
 * @returns 
 */
export const isVisible = (visibleIf, environment, locale) => {
    notNullNotUndefined(visibleIf)
    notNullNotUndefined(environment)
    notNullNotUndefined(locale)

    let envMatch;
    let locMatch;

    const mEnvs = visibleIf.filter(m=>!m.startsWith('locale:'))
    if (mEnvs.length > 0) {
        envMatch = mEnvs.includes(environment)
    } else {
        envMatch = true
    }

    const mLocales = visibleIf.filter(m=>m.startsWith('locale:'))
    if (mLocales.length > 0) {
        locMatch = mLocales.includes(`locale:${locale}`)
    } else {
        locMatch = true
    }

    return envMatch && locMatch
}