import { getAxiosDefault } from "apiClient/config/apiAxiosConfig";
import { requireValue } from "modules/picasso-modules/utils/validateUtils";
import { filterToQueryParams } from "utils/apiUtils";
import { notNullNotUndefined } from "utils/objectUtils";

export const getCurrentApp = () =>
  getAxiosDefault()(`/apps/current`).then((response) => {
    return response.data;
  });

export const getCurrentAppLandingPage = () => getAxiosDefault()('/apps/current/landingPageEffective')

export const getCurrentAppUserAccounts = ({page, size, fields}:{page:number, size:number, fields?:string[]}) => {
  notNullNotUndefined(size)
  notNullNotUndefined(page)

  let params = {
    fields:fields??['all'],
    page,
    size,
    sort: 'updatedAt,DESC',
  }

  return getAxiosDefault()(`/apps/current/userAccounts`, { params })
}

export const getCurrentAppUserAccount = (userAccountId) => getAxiosDefault()(`/apps/current/userAccounts/${userAccountId}`)

export const getCurrentAppUserCycleUrl = (userId) =>`/apps/current/userCycles?userIds=${userId}`

export const getCurrentAppWorkspaces = ({page, size, filter}) => {
  notNullNotUndefined(size)
  notNullNotUndefined(page)

  let params = {
    page,
    size,
    sort: 'updatedAt,DESC'
  }

  if (filter) {
    params = {
      ...params,
      ...filterToQueryParams(filter)
    }
  }

  return getAxiosDefault()(`/apps/current/workspaces`, { params }).then((response) => {
    return response.data;
  })
}

export const getCurrentAppWorkspace = (workspaceId) => {
  notNullNotUndefined(workspaceId)
  return getAxiosDefault().get(`/apps/current/workspaces/${workspaceId}`).then((res)=>res.data)
}

export const addWorkspaceEvent = (workspaceId, category, event) => {
  notNullNotUndefined(workspaceId)
  notNullNotUndefined(category)
  notNullNotUndefined(event)
  return getAxiosDefault().post(`/apps/current/workspaces/${workspaceId}/events`, {category, event })
}

export const getCurrentAppIntegrations = ({page, size}) => {
  notNullNotUndefined(size)
  notNullNotUndefined(page)

  return getAxiosDefault()(`/apps/current/integrations?sort=updatedAt,DESC&size=${size}&page=${page}`).then((response) => {
    return response.data;
  })
}

export const getCurrentAppEvents = ({page, size}) => {
  notNullNotUndefined(size)
  notNullNotUndefined(page)

  return getAxiosDefault()(`/apps/current/events?sort=createdAt,DESC&size=${size}&page=${page}`).then((response) => {
    return response.data;
  })
}

export const getCurrentUpgradeProposals = () => {
  return getAxiosDefault()(`/apps/current/upgradeProposals`).then((response) => {
    return response.data;
  })
}

export const getAppPlansInfo = () => getAxiosDefault()(`/apps/current/plans`)

export const getCurrentPlanComparison = (includeHidden, includePaidPlansOnly, includeCustom, subscribableForWorkspace, showUnlimitedAsIncluded) => getAxiosDefault()(`/apps/current/planComparison`, 
  { params: { includeHidden, includePaidPlansOnly, includeCustom, subscribableForWorkspace, showUnlimitedAsIncluded } }
)

export const getPaymentPaddleSettings = () =>
    getAxiosDefault().get(`/apps/current/payment/paddleSettings`)

export const deleteCurrentAppWorkspace = (workspaceId) => {
  notNullNotUndefined(workspaceId)
  return getAxiosDefault().delete(`/apps/current/workspaces/${workspaceId}`)
}

export const getSupportContact = () => getAxiosDefault().get(`/apps/current/supportContact`)

export const testValidationError = (msg) => {
  notNullNotUndefined(msg)
  return getAxiosDefault().post(`/apps/current/tests/validationError`, {dummyfield: msg})
}

export const testSimpleError = () => {
  return getAxiosDefault().post(`/apps/current/tests/simpleError`)
}

export const testQuotaError = () => {
  return getAxiosDefault().post(`/apps/current/tests/quotaError`)
}

export const testInternalServerError = () => {
  return getAxiosDefault().post(`/apps/current/tests/internalServerError`)
}

export const testEmailSend = () => {
  return getAxiosDefault().post(`/apps/current/tests/emailSend`)
}

export const setUserAlias = (userAccountId, alias) => {
  requireValue(userAccountId)
  requireValue(alias)
  return getAxiosDefault().post(`/apps/current/manage/users/setuseralias`, { userAccountId, alias })
}

export const setUserPw = (userAccountId, pw) => {
  requireValue(userAccountId)
  requireValue(pw)
  return getAxiosDefault().post(`/apps/current/manage/users/setuserpw`, { userAccountId, password: pw })
}

export const setUserName = (userAccountId, name) => {
  requireValue(userAccountId)
  requireValue(name)
  return getAxiosDefault().post(`/apps/current/manage/users/setusername`, { userAccountId, name })
}

export const setUserEmailConfirmed = (userAccountId) => {
  requireValue(userAccountId)
  return getAxiosDefault().post(`/apps/current/manage/users/setemailconfirmed`, { userAccountId })
}