import { findContent } from "modules/landingPage/utils/landingPageUtils"
import { getCurrentAppLandingPage } from "modules/yoio/api/appApi"
import { SubscriptionFeaturesInfoView, SubscriptionFeaturesInfoViewProps } from "modules/yoio/payment/SubscriptionFeaturesInfoView"
import { SimplePlanInfo } from "modules/yoio/payment/model/PaymentTypes"
import { useApp } from "modules/yoio/useApp"
import { FunctionComponent } from "react"
import useSWR from "swr"
import { isHavingValue } from "utils/objectUtils"
import { handleResponseDataGetContent } from "utils/useResolvedV2"
import { useAppSubscriptionPlanComparisonSwr } from "../numbers/useAppPlanComparison"

export interface AppPlanComparisonViewProps {
    includeHiddenPlans?: boolean
    includePaidPlansOnly?: boolean
    includeCustomPlans?: boolean
    subscriptionFeaturesInfoViewProps?: Omit<SubscriptionFeaturesInfoViewProps, 'subscriptionPlanComparison'>;
    immutable?: boolean
    features?: string[]
    subscribableForWorkspace?: string
    groupByCategory?: boolean
    categoriesOrder?: string[]
    showCtaButton?: boolean | ((plan:SimplePlanInfo)=>boolean)
    stickyPlans?: boolean
    onPlanCtaClick?: (plan:SimplePlanInfo) => void
    plansFilter?: string[]
    showUnlimitedAsIncluded?: boolean
}

export const AppPlanComparisonView: FunctionComponent<AppPlanComparisonViewProps> = ({includeHiddenPlans, includePaidPlansOnly, includeCustomPlans, subscriptionFeaturesInfoViewProps, immutable, features, subscribableForWorkspace, groupByCategory, categoriesOrder, showCtaButton, stickyPlans, onPlanCtaClick, plansFilter, showUnlimitedAsIncluded}) => {

    let { data: item, error } = useAppSubscriptionPlanComparisonSwr({ includeHiddenPlans, includePaidPlansOnly, includeCustomPlans, subscribableForWorkspace, showUnlimitedAsIncluded }, immutable, true)

    const loading = !item && !error

    if (loading) {
        return null
    }

    if (error) {
        return <>error</>
    }

    if (isHavingValue(plansFilter)) {
        item.plans = item.plans.filter(p=>plansFilter.includes(p.key))
    }

    if (features) {
        item = {
            ...item,
            features: item.features? [...item.features.filter(f=>features.includes(f.featureInfo.key))] : null
        }

        // Sort by provided features
        item.features.sort(function(a, b){  
            return features.indexOf(a.featureInfo.key) - features.indexOf(b.featureInfo.key)
        })
    }

    let sortAutoEnabled = isHavingValue(features) ? false : true

    return <SubscriptionFeaturesInfoView subscriptionPlanComparison={item} showFeatures={['all']} sortAutoEnabled={sortAutoEnabled} includeWithoutTitle {...subscriptionFeaturesInfoViewProps} groupByCategory={groupByCategory} categoriesOrder={categoriesOrder} showCtaButton={showCtaButton} stickyPlans={stickyPlans} onPlanCtaClick={onPlanCtaClick} />
}


export const AppPlanComparisonViewWithLandingPageSettings = ({includePaidPlansOnly, includeCustomPlans, subscribableForWorkspace, showCtaButton, stickyPlans, onPlanCtaClick}
    :{includePaidPlansOnly?:boolean, includeCustomPlans?:boolean, subscribableForWorkspace?: string, showCtaButton?: boolean | ((plan:SimplePlanInfo)=>boolean), stickyPlans?: boolean, onPlanCtaClick?: (plan:SimplePlanInfo) => void}) => {

    const { app } = useApp()

    //@ts-ignore
    const { data: landingPage } = useSWR(`/apps/${app.key}/landingPageEffective/`, ()=>getCurrentAppLandingPage().then(handleResponseDataGetContent))
    
    if (!landingPage?.pricingPage?.content) {
        return null;
    }
    
    const elements = findContent('AppPlanComparisonView', landingPage?.pricingPage?.content)
   
    if (elements?.length < 1) {
        return null;
    }
    
    const props = elements[0].props as AppPlanComparisonViewProps

    return (
        <><AppPlanComparisonView {...props} includePaidPlansOnly={includePaidPlansOnly} includeCustomPlans={includeCustomPlans} subscribableForWorkspace={subscribableForWorkspace} showCtaButton={showCtaButton} stickyPlans={stickyPlans} onPlanCtaClick={onPlanCtaClick} /></>
    )

}