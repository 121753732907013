import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { FunctionComponent } from "react";

const styleDef = {
    standard: theme=>({
        [`& .${tooltipClasses.tooltip}`]: {
            borderRadius: '10px',
            padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
            paddingBottom: theme.spacing(3),
            color: theme.palette.text.primary,
            backgroundColor: '#fcdc94',
            fontSize: '14px',
            maxWidth: '240px',
        }
    }),
    arrowWhentop: {
        [`& .${tooltipClasses.arrow}`]: {
            width: '25px',
            "&::before": {
                backgroundColor: '#fcdc94',
                transform: 'rotate(45deg) scale(1.5)',
                transformOrigin: 'bottom',
            },
        }
    },
    arrowWhenright: {
        [`& .${tooltipClasses.arrow}`]: {
            width: '25px',
            "&::before": {
                backgroundColor: '#fcdc94',
                //transform: 'rotate(135deg) scale(1.5) translateX(-50px)',
                transformOrigin: 'right',
            },
        }
    }
    
}

export const FeatureInfoTooltip: FunctionComponent<TooltipProps> = (props) => {
    const arrowStyle = styleDef[`arrowWhen${props.placement}`]
    const style = [styleDef.standard, arrowStyle ? arrowStyle:null]
    return <Tooltip {...props} componentsProps={{popper: {sx: style}}} />
}