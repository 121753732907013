import { alpha, Chip, chipClasses, ChipProps } from "@mui/material";
import { FC, forwardRef } from "react";
import { pushOneOrMany } from "utils/arrayUtils";

const styles = {
    chip: theme=>({
        fontSize: '11px',
        fontWeight: '500',
        fontFamily: theme.typography.fontFamily,
        height: '23px',
    })
}

const ChipStyled = forwardRef<HTMLDivElement, ChipProps>(({label, sx, ...props}, ref) => {

    const style = [styles.chip]
    if (sx) {
        pushOneOrMany(style, sx)
    }

    return <Chip label={label} size="small" {...props} sx={style} ref={ref} />
})

ChipStyled.displayName = 'ChipStyled'
export { ChipStyled as ChipStyled }

export const ChipSmooth: FC<ChipProps & {color?:string, fontWeight?:string}> = ({color, fontWeight, ...props}) => {

    let sx: Record<string, any>[] = [(theme)=>({
            backgroundColor: 'rgb(0 0 0 / 0.03)',
            [`& .${chipClasses.label}`]: {
                color: theme.palette.greyDark.main,
                fontWeight: fontWeight??'500',
                paddingLeft: '12px',
                paddingRight: '12px',
                paddingBottom: '1px',
            }
        })]
    if (color === 'warning') {
        sx.push((theme)=>({
            backgroundColor: alpha(theme.palette.warning.main, 0.12),
            [`& .${chipClasses.label}`]: {
                color: theme.palette.warning.main,
            }
        }))
    } 
    else if (color === 'success') {
        sx.push((theme)=>({
            backgroundColor: alpha(theme.palette.success.main, 0.12),
            [`& .${chipClasses.label}`]: {
                color: theme.palette.success.main,
            }
        }))
    }

    if (props.sx) {
        sx.push(props.sx)
    }

    return <Chip color={color} {...props} sx={sx}/>
    
}