import { Box } from "@mui/material"

export const IconCircle = ({children, size}) => {

    size = size || '52px'

    return (
        <Box sx={{
            color: 'primary', 
            borderRadius: '100%', 
            backgroundColor: 'rgb(235 244 253 / 45%)', 
            width: size, 
            height: size,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
        }}>
            <Box sx={{
                color: 'primary', 
                borderRadius: '100%', 
                backgroundColor: 'rgb(187 220 255 / 28%)', 
                width: `calc(${size} - 15px)`, 
                height: `calc(${size} - 15px)`, 
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                }}>
                {children}
            </Box>
        </Box>
    )

}

export const IconCircleSimple = ({children, size, sx}) => {

    size = size || '37px'

    return (
        <Box sx={[()=>({
            color: 'primary', 
            borderRadius: '100%', 
            backgroundColor: '#eff2f5', 
            width: size, 
            height: size,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            }), sx]}>
            {children}
        </Box>
    )

}

export const IconCircleSuccess = ({children, size, sx}) => {

    size = size || '37px'

    return (
        <Box sx={[()=>({
            color: '#178038', 
            borderRadius: '100%', 
            backgroundColor: '#e6f5ea', 
            width: size, 
            height: size,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            }), sx]}>
            {children}
        </Box>
    )

}

export const IconCircleError = ({children, size, sx}) => {

    size = size || '37px'

    return (
        <Box sx={[(theme)=>({
            color: theme.palette.error.main, 
            borderRadius: '100%', 
            backgroundColor: '#ffefee', 
            width: size, 
            height: size,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            }), sx]}>
            {children}
        </Box>
    )

}

export const IconCircleBorder = ({children, size, borderWidth, style}) => {

    size = size || '52px'
    borderWidth = borderWidth || '2px'

    const styles = {
        root: {
            width: size, 
            height: size,
            borderRadius: '9999px',
            border: borderWidth + ' solid rgb(255, 255, 255)',
            boxShadow: '0 0 0 1px #f4f5f7, 0 2px 4px -2px rgb(50 110 169 / 8%), 0 4px 8px -2px rgb(50 110 169 / 12%)',
            //boxShadow: 'rgb(0 0 0 / 11%) 0px 13px 15px -3px',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            ...style
        },
        inner: {
            boxShadow: 'inset 0 0 3px 1px rgb(48 65 98 / 5%)',
            borderRadius: '9999px',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
        }
    }

    return (
        <div css={styles.root}>
            <div css={styles.inner}>
                {children}
            </div>
        </div>
    )

}