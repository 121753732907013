import { Box, Typography } from "@mui/material"
import SimpleList, { SimpleListProps } from "modules/picasso-ui/dataviews/SimpleList"
import { IconCircleSuccess } from "modules/picasso-ui/specials/IconCircle"
import { copyObj, isFunction, isHavingValue } from "utils/objectUtils"
import { capitalize } from "utils/stringUtils"
import { FeatureInfoComparsion, SimplePlanInfo, SubscriptionPlanComparisonDto } from "./model/PaymentTypes"
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { VerticalAlignBox } from "modules/picasso-ui/layout/VerticalAlignBox"
import { Fragment, ReactNode, useState } from "react"
import { FeatureInfoTooltip } from "./component/FeatureInfoTooltip"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from "next-i18next"
import { FunctionComponent } from "react"
import { useApp } from "../useApp"
import { YoioApp } from "../YoioTypes"
import { Button } from "@mui/material"
import { PaymentPeriod, getCurrencySign } from "modules/picasso-modules/subscription/PaymentTypes"
import GradeIcon from '@mui/icons-material/Grade';

export interface SubscriptionFeaturesInfoViewProps {
    subscriptionPlanComparison:SubscriptionPlanComparisonDto
    hideColTitles?:boolean
    variant?: 'comparison' | 'current'
    showKey?:boolean 
    showPlanKey?:boolean
    /**
     * Star next to paid plan
     */
    showPlanStar?: boolean
    showCategory?:boolean
    includeWithoutTitle?:boolean
    showFeatures?:string[]
    showUnlimitedAsIncluded?: boolean
    sortAutoEnabled?: boolean
    sortFeaturesWithQuotaFirst?: boolean
    groupByCategory?: boolean 
    categoriesOrder?: string[]
    showCtaButton?: boolean | ((plan:SimplePlanInfo)=>boolean)
    stickyPlans?: boolean
    onPlanCtaClick?: (plan:SimplePlanInfo) => void
    renderTitle?: (title: string, isAdditional?: boolean, infoElem?: ReactNode)=>ReactNode
    listProps?: SimpleListProps<any>
    planNameStyle?: any
    infoStyle?: any
}

/**
 * 
 * @param variant 
 *      'current' or 'comparison'
 * @returns 
 */
export const SubscriptionFeaturesInfoView: FunctionComponent<SubscriptionFeaturesInfoViewProps> = ({subscriptionPlanComparison, hideColTitles, variant, showKey, showPlanKey, showCategory, includeWithoutTitle, showFeatures, showUnlimitedAsIncluded, sortFeaturesWithQuotaFirst, sortAutoEnabled, groupByCategory, categoriesOrder, showCtaButton, stickyPlans, onPlanCtaClick, renderTitle, listProps, planNameStyle, showPlanStar, infoStyle}) => {

    const { app } = useApp() as { app: YoioApp }

    const { t } = useTranslation(['yoio', app.i18nNamespaceDefault])

    variant = variant || 'comparison'

    const scrollMode = subscriptionPlanComparison?.plans?.length > 2

    const nameRatio  = subscriptionPlanComparison?.plans?.length > 2 ? 2 : 2
    const plansRatio = subscriptionPlanComparison?.plans?.length > 2 ? 5 : 2

    const [isSticky, setSticky] = useState<Boolean>()

    const handleScrollWithStickyPlans = (e) => {
        if (e.target.scrollTop > 0 && !isSticky) {
            setSticky(true)
        } else if (e.target.scrollTop === 0 && isSticky) {
            setSticky(false)
        }
    }

    let itemsPrepared = subscriptionPlanComparison.features
        .filter((i:FeatureInfoComparsion)=>{
            if (showFeatures && showFeatures.includes('all')) {
                return includeWithoutTitle === true || isHavingValue(i.featureInfo.title)
            }

            if (i.quotaPerPlan.size === 0 || Object.keys(i.quotaPerPlan).length === 0
             || (subscriptionPlanComparison.highestPlan && !Object.keys(i.quotaPerPlan).includes(subscriptionPlanComparison.highestPlan))) {
                return false;
            }

            if (variant === 'comparison' && subscriptionPlanComparison.myPlan) {
                const isIncludedInMyPlan = subscriptionPlanComparison.myPlan ? (i.quotaPerPlan[subscriptionPlanComparison.myPlan] === null || i.quotaPerPlan[subscriptionPlanComparison.myPlan] === -99) : null
                
                if (showFeatures && showFeatures.includes('sameInHigherPlan')) {
                    return isIncludedInMyPlan;
                }

                if (isIncludedInMyPlan) {
                    return false;
                }
            }

            return includeWithoutTitle === true || isHavingValue(i.featureInfo.title)
        })

        if (sortAutoEnabled !== false) {
            itemsPrepared.sort((a:FeatureInfoComparsion,b:FeatureInfoComparsion)=>{

                // included: it means its an 'on'/'off' feature, not a limited feature and is shown as 'included'. 'unlimited' features can be shown as included
    
    
                const aIsIncludedInHighestPlan = a.featureInfo.quota === -99 || a.featureInfo.quota === null
                const bIsIncludedInHighestPlan = b.featureInfo.quota === -99 || b.featureInfo.quota === null
    
                const aIsWithQuotaLimit = a.quotaPerPlan && Object.values(a.quotaPerPlan).some(val=>val!==null)
                const bIsWithQuotaLimit = b.quotaPerPlan && Object.values(b.quotaPerPlan).some(val=>val!==null)
    
                const aIsIncludedInMyPlan = subscriptionPlanComparison.myPlan ? (a.quotaPerPlan[subscriptionPlanComparison.myPlan] === -99 || a.quotaPerPlan[subscriptionPlanComparison.myPlan] === null): null
                const bIsIncludedInMyPlan = subscriptionPlanComparison.myPlan ? (b.quotaPerPlan[subscriptionPlanComparison.myPlan] === -99 || b.quotaPerPlan[subscriptionPlanComparison.myPlan] === null): null
    
                const aIsAttentionFeature = aIsIncludedInHighestPlan && aIsIncludedInMyPlan === false
                const bIsAttentionFeature = bIsIncludedInHighestPlan && bIsIncludedInMyPlan === false
    
                // If it's a comparison and the feature is included in my current plan, then show it last
                if (variant === 'comparison') {
                    if (aIsAttentionFeature && !bIsAttentionFeature) {
                        return -1
                    } else if (!aIsAttentionFeature && bIsAttentionFeature) {
                        return 1
                    }
    
                    if (sortFeaturesWithQuotaFirst) {
                        if (aIsWithQuotaLimit && !bIsWithQuotaLimit) return -1
                        if (!aIsWithQuotaLimit && bIsWithQuotaLimit) return 1
                    }
    
                    if (aIsIncludedInMyPlan && bIsWithQuotaLimit) {
                        return 1
                    } else  if (aIsWithQuotaLimit && bIsIncludedInMyPlan) {
                        return -1
                    }
    
                }
    
                //show 'inluded' features (null-quota) before quota features
                //also show 'unlimited features' before quota features. 'unlimited' is shown as 'included' -> 'unlimited included' because it looks better.
                if (aIsIncludedInHighestPlan && !bIsIncludedInHighestPlan) {
                    return -1
                } else if (!aIsIncludedInHighestPlan && bIsIncludedInHighestPlan) {
                    return 1
                }
    
                if (a.featureInfo.category && b.featureInfo.category) {
    
                    if (a.featureInfo.category === 'other' && b.featureInfo.category != 'other') {
                        return 1
                    } else if (b.featureInfo.category === 'other' && a.featureInfo.category != 'other') {
                        return -1
                    }

                    if (a.featureInfo.key !== null && b.featureInfo.key !== null) {
                        return (a.featureInfo.category+a.featureInfo.key).localeCompare(b.featureInfo.category+b.featureInfo.key)
                    }
    
                    return ((a.featureInfo.category||'Z')<(b.featureInfo.category||'Z')?-1:((a.featureInfo.category||'Z')>(b.featureInfo.category||'Z')?1:0))
                }
    
    
                return 0
    
    /*             const categoryCompare = ((a.featureInfo.category||'Z')<(b.featureInfo.category||'Z')?-1:((a.featureInfo.category||'Z')>(b.featureInfo.category||'Z')?1:0))
                return categoryCompare */
            })
        }


    if (showFeatures && showFeatures.includes('additionalQuotas')) {

        const addititionalFeatures = subscriptionPlanComparison.features.filter(f=>{
            return isHavingValue(f.quotaMyAdditional)
        })
        .map(f=>{
            let updated = copyObj(f) as any
            if (updated.featureInfo.title) {
                updated.featureInfo.title = t('yoio:access.plans.myPlan.quotaMyAdditionalFeatureTitle', {count: updated.quotaMyAdditional, title: updated.featureInfo.title })
                updated.isAdditional = true
            }
            return updated
        })

        if (addititionalFeatures.length > 0) {
            itemsPrepared = [...itemsPrepared, ...addititionalFeatures]
        }

    }

    const categories = []

    if (groupByCategory) {
        const categoriesMap = {}
        for (const item of itemsPrepared) {
            categoriesMap[item.featureInfo.category] = categoriesMap[item.featureInfo.category] || []
            categoriesMap[item.featureInfo.category].push(item)
        }
        if (categoriesOrder) {
            const rest = Object.keys(categoriesMap)
            for (const categoryKey of categoriesOrder) {
                if (categoriesMap[categoryKey]) {
                    categories.push({ category: categoryKey, items: categoriesMap[categoryKey] })

                    // Remove from rest
                    const index = rest.indexOf(categoryKey);
                    rest.splice(index, 1);
                }
            }
            for (const categoryKey of rest) {
                categories.push({ category: categoryKey, items: categoriesMap[categoryKey] })
            }
        } else {
            for (const [category, items] of Object.entries(categoriesMap)) {
                categories.push({ category, items })
            }
        }
    } else {
        categories.push({
            category: "all",
            items: itemsPrepared
        })
    }

    const renderQuotaValueDisplay = (feature:FeatureInfoComparsion, quota: number, plan: SimplePlanInfo) => {

        //const showUnlimitedAsIncluded = true//feature.settings?.showUnlimitedAsIncluded

        const planDisplaySettings = plan.settings
        const recommended = planDisplaySettings ? planDisplaySettings.recommended : null
        // @ts-ignore
        const isAdditional = feature.isAdditional

        //if highest plan has unlimited and this value is not unlimted and showUnlimitedAsIncluded is true, then show the current like not included
        const showLikeNotIncluded = showUnlimitedAsIncluded && quota !== -99 && feature.quotaPerPlan && feature.quotaPerPlan[subscriptionPlanComparison.highestPlan] === -99

        if (isAdditional) {
            return (
                <VerticalAlignBox sx={{flexGrow: 1, flexBasis: 1,justifyContent: 'center', flexShrink: 0,}}>
                     {/** @ts-ignore */}
                    <IconCircleSuccess size="25px"><CheckIcon sx={{fontSize: '18px'}}/></IconCircleSuccess>
                </VerticalAlignBox>
            )
        }

        return (
            <VerticalAlignBox sx={{flexGrow: 1, flexBasis: 1, justifyContent: 'center', flexShrink: 0,}}>
                {isHavingValue(quota) && (quota !== -99 || showUnlimitedAsIncluded !== true) && showLikeNotIncluded !== true?
                    <>
                        {recommended || variant === 'current' ? 
                            <Typography sx={{fontWeight: '700', fontSize: '18px'}} component="div">{quota === -99 ? 'Unlimited' : quota}</Typography>
                            :
                            <Typography sx={{fontWeight: '500', color: 'text.secondary' }} component="div">{quota === -99 ? 'Unlimited' : quota}</Typography>
                        }
                    </>
                    :
                    (
                        (quota === null || (showUnlimitedAsIncluded === true && quota === -99)) ?
                            // @ts-ignore
                            <IconCircleSuccess size="25px"><CheckIcon sx={{fontSize: '18px'}}/></IconCircleSuccess>
                            :
                            <CloseIcon sx={{fontSize: '18px', color: '#ced2db'}}/>
                    )
                }
            </VerticalAlignBox>
        )
    }

    renderTitle = renderTitle ?? ((title, isAdditional, infoElem) => {
        return (
            <VerticalAlignBox justifyContent="space-between">
                <Typography component="div" sx={{fontWeight: isAdditional?600:null}} flex={3} color="text.secondary">
                {/* {category && <>{category} - </>} */}
                    {title}
                </Typography>
                {infoElem}
            </VerticalAlignBox>
        )
    });

    const renderItem = (item) => {

        const quotaPerPlan = item.quotaPerPlan
        let { category, title, info, key } = item.featureInfo
        const isAdditional = item.isAdditional

        const tooltipTitle = isAdditional ? t('yoio:access.plans.myPlan.quotaMyAdditionalTooltip') : info

        title = title ? capitalize(title) : '(no title)'

        const infoElem = tooltipTitle ? <>
            {/* @ts-ignore */} 
            <FeatureInfoTooltip title={tooltipTitle} arrow placement="right">
                <VerticalAlignBox>
                    <InfoOutlinedIcon sx={[{color: '#b9bfc8', fontSize: '22px'}, infoStyle]} />
                </VerticalAlignBox>
            </FeatureInfoTooltip>
        </>:null

        return (
            <Box pl={0} pr={1} pt={0.5} pb={0.5} alignItems="center" display="flex" justifyContent="space-between" width="100%" gap="30px">
                <VerticalAlignBox flex={nameRatio}>

                    {showCategory &&<Box width="150px"><Typography>{category}</Typography></Box>}

                    <Box width="100%">
                        {showKey &&
                            <Typography sx={{color: 'text.secondary', fontSize: '10px'}}>{key}</Typography>
                        }
                        {/* @ts-ignore */} 
                        {renderTitle(title, isAdditional, infoElem)}
                    </Box>
                </VerticalAlignBox>
                
                <VerticalAlignBox flex={plansRatio}>
                    {subscriptionPlanComparison.plans.map(p=><Fragment key={p.key}>{renderQuotaValueDisplay(item, quotaPerPlan[p.key], p)}</Fragment>)}
                </VerticalAlignBox>

            </Box>
        )
    }

    const renderTitles = () => {
        return (
            <>
            {hideColTitles !== true &&
                <Box 
                    display="flex" 
                    justifyContent="space-between" 
                    width="100%" pt={1} pb={1} pr={1} gap="30px" 
                    sx={[
                        stickyPlans?{position: 'sticky', top: '0', backgroundColor: '#fff', paddingBottom: '24px'}:null,
                        isSticky?{boxShadow: '#cbcbcb 0px 8px 14px -11px', borderBottom: '1px solid #eaebef'}:null,
                    ]}>
                    <VerticalAlignBox flex={nameRatio}>
                        {/* reserve space */}
                        {showCtaButton && <Typography fontSize="2em">Choose the plan<br/>that's right for you</Typography>}
                    </VerticalAlignBox>
                    <VerticalAlignBox flex={plansRatio}>
                        {subscriptionPlanComparison.plans.map(p=>{
                            
                            const variant = (p.variants?.find(v=>v.period===PaymentPeriod.annually) || p.variants?.[0])
                            const currency = variant && variant.priceMonthly ? Object.keys(variant.priceMonthly)[0] : null

                            //@ts-ignore
                            const showCtaButtonForPlan = (isFunction(showCtaButton) && showCtaButton(p)) ?? showCtaButton
       
                            return (
                                <VerticalAlignBox sx={{flex: 1, justifyContent: 'center'}} key={p.key}>
                                    <Box display="flex" flexDirection="column" textAlign="center">
                                        <Typography sx={[{fontWeight: '500', fontSize: '18px', display: 'flex', alignItems: 'center', justifyContent: 'center'}, planNameStyle]} color="primary.main" component="div">{showPlanStar && p.free === false && <GradeIcon sx={theme=>({ fontSize: '18px', marginRight: '2px', paddingBottom: '1px', color: theme.palette.primary.main})}/>} {p.name}</Typography>
                                        {showPlanKey && <Typography fontSize="0.8em" color="text.secondary">{p.key}</Typography>}
                                        {showCtaButton && variant && <Typography mt={1} pb={1} fontWeight={600} fontSize="16px">{getCurrencySign(currency)}{variant.priceMonthly[currency]}/{t(`yoio:access.plans.paymentPeriod.monthly.unitPer`)}</Typography>}
                                        {showCtaButton && <Box mt={1} style={{ visibility: showCtaButtonForPlan !== true ? 'hidden':undefined }}><Button color="primary" variant="contained" onClick={()=>onPlanCtaClick?.(p)}>Go {p.name}</Button></Box>}
                                    </Box>
                                </VerticalAlignBox>
                            )
                        })}
                    </VerticalAlignBox>
                </Box>
            }
            </>
        )
    }

    return (
        <>
                <Box width="100%" sx={[{
                    overflowX: scrollMode?'auto':null,
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                }, stickyPlans?{height: '100%'}:null]}>
                    {/** titles not grow correctly when scrolled on small screens, but keeps sticky */}
                    {stickyPlans && renderTitles()}
                    <Box 
                        minWidth={scrollMode?'700px':null} 
                        sx={stickyPlans? {overflow: 'auto'}:null}
                        flexGrow={1}
                        onScroll={stickyPlans ? handleScrollWithStickyPlans : undefined}
                        >
                    {/** when titles are rendered here, the titles container grows together and is also positioned correctly when table is so small that it is scrolled  */}
                    {!stickyPlans && renderTitles()}
                    {categories?.map(({category, items}, idx)=>{
                        return (
                            <Fragment key={category}>
                                {categories.length > 1 && <Typography mt={idx > 0 ? 3:0} py={1} fontWeight={500} fontSize="1.2em">{t(`${app.i18nNamespaceDefault}:app.featureCategories.${category}.title`)}</Typography>}
                                <SimpleList 
                                    getRowKey={i=>{
                                        if (i.isAdditional) {
                                            return i.featureInfo.key+'_additional'
                                        } else {
                                            return i.featureInfo.key
                                        }
                                    }}
                                    items={items} 
                                    renderItem={renderItem}
                                    {...listProps}
                                    listItemProps={{
                                        withPadding: true,
                                        hoverHighlight: false,
                                        ...listProps?.listItemProps
                                    }}
                                />
                            </Fragment>
                        )
                    })}

                    </Box>
                </Box>
        </>
    )

}